'use strict';

// jQuery init
// eslint-disable-next-line no-multi-assign
window.jQuery = window.$ = require('jquery');

require('slick-carousel');
require('selectric');
require('./thirdParty/bootstrap');
require('lazysizes');
require('arrive');

setTimeout(function () {
    $('.lazyload-low-prio').addClass('lazyload').removeClass('lazyload-low-prio');
}, 300);

$(document).arrive('.lazyload-low-prio', function () {
    var $this = $(this);
    setTimeout(function () {
        $this.addClass('lazyload').removeClass('lazyload-low-prio');
    }, 300);
});
